import * as api from '.';

const mobileApi = {

  login: async (username: string, password: string) => {
    const data = {
      username,
      password
    };
    return api.POST('/api/mobile/login', null, data);
  },

  getTestCatalog: async () => {
    return api.GET('/api/mobile/test/catalog', null, null);
  },

  getTests: async (testType: string, referenceCode: string) => {
    const data = {
      testType,
      referenceCode
    };
    return api.GET('/api/mobile/tests/:testType/:referenceCode', data, null);
  },

  getTest: async (id: string) => {
    return api.GET('/api/mobile/test/:id', { id }, null);
  }

};

export default mobileApi;
