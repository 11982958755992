import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';
import { Button, ErrorBlock } from 'antd-mobile';

const NoMatched: React.FC = () => {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  return (
    <ErrorBlock
      status="empty"
      title="页面不存在"
      description={`路径：${location.pathname.substring(1)}`}
      fullPage
    >
      <Button
        color="primary"
        style={{ marginTop: 15 }}
        onClick={() => navigate('/')}
      >
        返回首页
      </Button>
    </ErrorBlock>
  );
};

export default NoMatched;
