const formatTime = (time: string) => {
  if (!time) {
    return '';
  }
  const parts: string[] = time.split(' ');
  const ymd = parts[0].split('-');
  const hms = parts[1].split(':');
  return ymd[0] + '年' + Number(ymd[1]) + '月' + Number(ymd[2]) + '日' + Number(hms[0]) + '点' + Number(hms[1]) + '分';
};

export { formatTime };
