import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN'
import RouterView from '../RouterView';
import './index.css';

const App: React.FC = () => {
  const cacluateHeight = () => {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    cacluateHeight();
    window.removeEventListener('resize', cacluateHeight);
    window.addEventListener('resize', cacluateHeight);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigProvider locale={zhCN}>
      <RouterView />
    </ConfigProvider>
  );
};

export default App;
