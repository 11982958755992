import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';
import {
  Button,
  CapsuleTabs,
  Dialog,
  DotLoading,
  ErrorBlock,
  Image,
  List,
  ResultPage,
  Space,
  SpinLoading
} from 'antd-mobile';
import {
  FileWrongOutline,
  PlayOutline
} from 'antd-mobile-icons';
import mobileApi from '../api/mobileApi';
import { formatTime } from '../utils/dateUtil';
import soundImageData from '../data/soundImageData';

const Replay: React.FC = () => {
  const location: Location = useLocation();
  const pathname: string = location.pathname;
  const parts: string[] = pathname.split('/');
  const testId: string = parts.length >= 3 ? parts[2] : '';

  const [initializationTime, setInitializationTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [test, setTest] = useState<any>({});
  const [audioInterval, setAudioInterval] = useState<number>(3);
  const [incorrectWords, setIncorrectWords] = useState<any[]>([]);
  const [words, setWords] = useState<any[]>([]);
  const [playingIndex, setPlayingIndex] = useState<number>(-1);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [replaying, setReplaying] = useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();
  let lastRequestTime: number = 0;

  const loadData = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await mobileApi.getTest(testId);
    const data: any = res.data || {};
    if (res.code === 200) {
      if (data.category) {
        setName(data.category.name);
      } else if (data.unit) {
        setName(data.unit.name);
      } else {
        setName('');
      }
      setTest(data.test || {});
      setAudioInterval((data.test || {}).audioInterval || 3);
      const words: any[] = [];
      const details: any[] = data.details || [];
      details.forEach((item: any) => {
        if (!(item.selfJudgment === true || item.correct === true)) {
          words.push(item);
        }
      });
      setIncorrectWords(words);
      setWords([...words]);
    } else {
      setErrorMessage(res.message || '数据加载失败');
    }
    setLoading(false);
  };

  const startReplay = () => {
    if (replaying) {
      return;
    }

    if (!words.length) {
      Dialog.alert({
        content: '没有可供复盘听写的错词',
        confirmText: '确定'
      });
      return;
    }

    const count: number = words.length;
    const temp: any[] = [...incorrectWords];
    const currentWords: any[] = [];
    for (let i = 0; i < count; i++) {
      const index: number = Math.floor(Math.random() * temp.length);
      currentWords.push(temp[index]);
      temp.splice(index, 1);
    }
    setWords(currentWords);
    setReplaying(true);
    setCurrentIndex(-1);
    playNext(true, currentWords);
  };

  const playNext = (immediate: boolean, currentWords?: any[]) => {
    const audio: any = window.document.getElementById('audio');
    const index = currentIndex + 1;
    if (!immediate) {
      if (!replaying) {
        setPlayingIndex(-1);
        return;
      }

      const duration: number = Math.max(audioInterval * 1000, 50);
      const time: number = audio.dataset.initializationTime;
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        const currentAudio: any = window.document.getElementById('audio');
        const currentTime: number = currentAudio ? currentAudio.dataset.initializationTime : 0;
        if (currentTime === time) {
          playNext(true);
        }
      }, duration);
      return;
    }

    if (index >= incorrectWords.length) {
      setReplaying(false);
      setCurrentIndex(-1);
      Dialog.alert({
        content: '复盘听写完毕',
        confirmText: '确定'
      });
      return;
    }

    setPlayingIndex(-1);
    setCurrentIndex(index);
    scrollIntoView(index);
    audio.src = `/api/word/audio/${(currentWords || words)[index].wordId}`;
    audio.play();
  };

  const playAudio = (index: number, wordId: string) => {
    if (replaying) {
      return;
    }

    setPlayingIndex(index);
    setCurrentIndex(-1);
    const audio: any = window.document.getElementById('audio');
    if (audio) {
      audio.src = `/api/word/audio/${wordId}`;
      audio.play();
    }
  };

  const scrollIntoView = (index: number) => {
    const selectors: string = `.words-list .adm-list-body-inner .adm-list-item:nth-child(${index + 1})`;
    const element: Element | null = window.document.querySelector(selectors);
    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  useEffect(() => {
    setInitializationTime(new Date().getTime());
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="replay-page">
      {
        loading ? (
          <Space
            align="center"
            direction="vertical"
            className="loading-box"
          >
            <SpinLoading color="primary" />
            <span>加载中...</span>
          </Space>
        ) : (
          errorMessage ? (
            <ErrorBlock
              status="default"
              title={errorMessage}
              description="请稍后重试"
              fullPage
            >
              <Button
                color="primary"
                style={{ marginTop: 15 }}
                onClick={() => navigate('/replay')}
              >
                返回上一页
              </Button>
            </ErrorBlock>
          ) : (
            <>
              <audio
                id="audio"
                data-initialization-time={initializationTime}
                onEnded={() => playNext(false)}
              />
              <ResultPage
                status="info"
                icon={<FileWrongOutline />}
                title="9988雅思听力答案词"
                description={name}
              >
                <CapsuleTabs>
                  <CapsuleTabs.Tab
                    key="words"
                    title="错词复盘"
                    disabled={replaying}
                  >
                    <>
                      <p>{`${formatTime(test.endTime)}提交的${test.parentId ? '错词复盘' : '完整'}听写，错词如下：`}</p>
                      <List className="incorrect-words-list">
                        {
                          incorrectWords.map((item: any, index: number) => (
                            <List.Item key={item.id}>
                              {item.description}
                              <div className="sound-box">
                                {playingIndex === index ? (
                                  <DotLoading />
                                ) : (
                                  <Image
                                    src={soundImageData}
                                    fit="fill"
                                    placeholder={null}
                                    className="sound"
                                    onClick={() => playAudio(index, item.wordId)}
                                  />
                                )}
                              </div>
                            </List.Item>
                          ))
                        }
                      </List>
                    </>
                  </CapsuleTabs.Tab>
                  <CapsuleTabs.Tab
                    key="replay"
                    title="复盘听写"
                  >
                    <p>{`对如下答案词做错词复盘听写（笔纸听写/英音${test.randomable ? '随机乱序' : '正序'}）`}</p>
                    <Button
                      block
                      color="primary"
                      loading={replaying}
                      onClick={() => startReplay()}
                    >
                      开始听写
                    </Button>
                    <p className="progress-text">{currentIndex + 1} / {words.length}</p>
                    <List className={`words-list ${replaying ? 'replaying' : ''}`}>
                      {
                        words.map((item: any, index: number) => (
                          <List.Item
                            key={item.id}
                            className={index === currentIndex ? 'current' : ''}
                          >
                            <>
                              {index === currentIndex ? (
                                  <PlayOutline />
                              ) : null}
                              <span>{item.description}</span>
                            </>
                          </List.Item>
                        ))
                      }
                    </List>
                  </CapsuleTabs.Tab>
                </CapsuleTabs>
              </ResultPage>
            </>
          )
        )
      }
    </div>
  );
};

export default Replay;
