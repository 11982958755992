import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import AudioIndex from './AudioIndex';
import Audio from './Audio';
import ReplayIndex from './ReplayIndex';
import ReplayTests from './ReplayTests';
import Replay from './Replay';
import NoMatched from './NoMatched';

const RouterView: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/audio" element={<AudioIndex />} />
      <Route path="/audio/*" element={<Audio />} />
      <Route path="/replay" element={<ReplayIndex />} />
      <Route path="/tests/*" element={<ReplayTests />} />
      <Route path="/replay/*" element={<Replay />} />
      <Route path="/*" element={<NoMatched />} />
    </Routes>
  );
};

export default RouterView;
