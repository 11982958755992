import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';
import {
  Button,
  Card,
  ErrorBlock,
  List,
  Space,
  SpinLoading,
  Toast
} from 'antd-mobile';
import {
  AddCircleOutline,
  MinusCircleOutline
} from 'antd-mobile-icons';
import mobileApi from '../api/mobileApi';
import { formatTime } from '../utils/dateUtil';

const ReplayTests: React.FC = () => {
  const location: Location = useLocation();
  const pathname: string = location.pathname;
  const parts: string[] = pathname.split('/');
  const testType: string = parts.length >= 3 ? parts[2] : '';
  const referenceCode: string = parts.length >= 4 ? parts[3] : '';

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [tests, setTests] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<boolean[]>([]);

  const navigate: NavigateFunction = useNavigate();
  let lastRequestTime: number = 0;

  const loadData = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await mobileApi.getTests(testType, referenceCode);
    const data: any = res.data || {};
    if (res.code === 200) {
      if ((data || []).length) {
        setName(data[0].referenceName || '');
        setTests(data);
        const list: boolean[] = [];
        data.forEach((item: any) => list.push(true));
        setExpanded(list);
      } else {
        setErrorMessage('Not Found');
      }
    } else {
      setErrorMessage(res.message || '数据加载失败');
    }
    setLoading(false);
  };

  const openReplayPage = (test: any) => {
    if (test.accuracy >= 1) {
      Toast.show({
        content: '正确率100%不需复盘'
      });
      return;
    }
    navigate('/replay/' + test.id);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="replay-tests-page">
      {
        loading ? (
          <Space
            align="center"
            direction="vertical"
            className="loading-box"
          >
            <SpinLoading color="primary" />
            <span>加载中...</span>
          </Space>
        ) : (
          errorMessage ? (
            <ErrorBlock
              status="default"
              title={errorMessage}
              description="请稍后重试"
              fullPage
            >
              <Button
                color="primary"
                style={{ marginTop: 15 }}
                onClick={() => navigate('/replay')}
              >
                返回上一页
              </Button>
            </ErrorBlock>
          ) : (
            <Card
              title={
                <>
                  <p>9988雅思听力答案词</p>
                  <span>{name}</span>
                </>
              }
              headerClassName="tests-title"
              bodyClassName="tests-body"
            >
              <List>
                {
                  tests.map((testObject: any, testObjectIndex: number) => (
                    <div key={testObject.test.id}>
                      <List.Item
                        key={testObject.test.id}
                        onClick={() => openReplayPage(testObject.test) }
                      >
                        <div
                          className="expand-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const list: boolean[] = expanded.slice();
                            list[testObjectIndex] = !list[testObjectIndex];
                            setExpanded(list);
                          }}
                        >
                          {expanded[testObjectIndex] ? <MinusCircleOutline /> : <AddCircleOutline />}
                        </div>
                        {`完整听写 / ${formatTime(testObject.test.endTime)}提交 / 英音${testObject.test.randomable ? '随机乱序' : '正序'} / 共听写${testObject.test.total}个词条 / `}
                        {`正确${testObject.test.numberOfCorrectAnswers}个 / `}
                        <span className="red">错误{testObject.test.total - testObject.test.numberOfCorrectAnswers}个</span>
                        {` / 正确率${(Math.round(testObject.test.numberOfCorrectAnswers / testObject.test.total * 10000) / 100).toFixed(2)}%`}
                      </List.Item>
                      {
                        (testObject.children || []).map((item: any, index: number) => (
                          <List.Item
                            key={item.id}
                            style={{
                              backgroundColor: '#f3f3f3',
                              display: expanded[testObjectIndex] ? 'block' : 'none'
                            }}
                            onClick={() => openReplayPage(item)}
                          >
                            <div className="replay-test-index">
                              {index + 1}
                            </div>
                            {`错词复盘听写 / ${formatTime(item.endTime)}提交 / 英音${item.randomable ? '随机乱序' : '正序'} / 共听写${item.total}个词条 / `}
                            {`正确${item.numberOfCorrectAnswers}个 / `}
                            <span className="red">错误{item.total - item.numberOfCorrectAnswers}个</span>
                            {` / 正确率${(Math.round(item.numberOfCorrectAnswers / item.total * 10000) / 100).toFixed(2)}%`}
                          </List.Item>
                        ))
                      }
                    </div>
                  ))
                }
              </List>
            </Card>
          )
        )
      }
    </div>
  );
};

export default ReplayTests;
