import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { NavigateFunction } from 'react-router-dom';
import {
  Button,
  ErrorBlock,
  List,
  Space,
  SpinLoading,
  Tabs,
  Toast
} from 'antd-mobile';
import mobileApi from '../api/mobileApi';

const ReplayIndex: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [units, setUnits] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState<string>('unit');

  const navigate: NavigateFunction = useNavigate();
  let lastRequestTime: number = 0;

  const loadData = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await mobileApi.getTestCatalog();
    const data: any = res.data || {};
    if (res.code === 200) {
      const units = data.units || [];
      const categories = data.categories || [];
      let map = data.unitTestCountMap || {}
      for (let i = 0; i < units.length; i++) {
        units[i].testCount = map[units[i].id] || 0;
      }
      map = data.categoryTestCountMap || {};
      for (let i = 0; i < categories.length; i++) {
        categories[i].testCount = map[categories[i].id] || 0;
      }
      setUnits(units);
      setCategories(categories);
    } else {
      setErrorMessage(res.message || '数据加载失败');
    }
    setLoading(false);
  };

  const openReplayPage = (item:any, testType: string) => {
    if (item.testCount) {
      navigate('/tests/' + testType + '/' + item.code);
    } else {
      Toast.show({
        content: '没有可供复盘的听写训练'
      });
    }
  };

  useEffect(() => {
    const key: string = localStorage.getItem('replay-index-page:activeKey') || 'unit';
    setActiveKey(key);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="replay-index-page">
      {
        loading ? (
          <Space
            align="center"
            direction="vertical"
            className="loading-box"
          >
            <SpinLoading color="primary" />
            <span>加载中...</span>
          </Space>
        ) : (
          errorMessage ? (
            <ErrorBlock
              status="default"
              title={errorMessage}
              description="请稍后重试"
              fullPage
            >
              <Button
                color="primary"
                style={{ marginTop: 15 }}
                onClick={() => navigate('/')}
              >
                返回首页
              </Button>
            </ErrorBlock>
          ) : (
            <Tabs
              activeKey={activeKey}
              onChange={key => {
                setActiveKey(key);
                localStorage.setItem('replay-index-page:activeKey', key);
              }}
            >
              <Tabs.Tab
                key="unit"
                title={
                  <>
                    <p>错词复盘</p>
                    <span>（按“单元”查找）</span>
                  </>
                }
              >
                <List className="unit-list">
                  {
                    (units || []).map((item: any, index: number) => (
                      <List.Item
                        key={index.toString()}
                        className={item.testCount ? 'selectable' : ''}
                        onClick={() => openReplayPage(item, 'Unit')}
                      >
                        {item.name}
                      </List.Item>
                    ))
                  }
                </List>
              </Tabs.Tab>
              <Tabs.Tab
                key="category"
                title={
                  <>
                    <p>错词复盘</p>
                    <span>（按“易丢分点专项”查找）</span>
                  </>
                }  
              >
                <List className="category-list">
                  {
                    (categories || []).map((item: any, index: number) => (
                      <List.Item
                        key={index.toString()}
                        className={item.testCount ? 'selectable' : ''}
                        onClick={() => openReplayPage(item, 'Category')}
                      >
                        {item.name}
                      </List.Item>
                    ))
                  }
                </List>
              </Tabs.Tab>
            </Tabs>
          )
        )
      }
    </div>
  );
};

export default ReplayIndex;
