const play: string = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyBmaWxsPSIjMjYyNjI2IiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy41NTUsMy4xNjhBMSwxLDAsMCwwLDYsNFYyMGExLDEsMCwwLDAsMS41NTUuODMybDEyLThhMSwxLDAsMCwwLDAtMS42NjRaTTgsMTguMTMxVjUuODY5TDE3LjIsMTJaIi8+PC9zdmc+';

const pause: string = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyBmaWxsPSIjMjYyNjI2IiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSwzQTEsMSwwLDAsMCw0LDRWMjBhMSwxLDAsMCwwLDEsMWg1YTEsMSwwLDAsMCwxLTFWNGExLDEsMCwwLDAtMS0xWk05LDE5SDZWNUg5Wk0xNCwzYTEsMSwwLDAsMC0xLDFWMjBhMSwxLDAsMCwwLDEsMWg1YTEsMSwwLDAsMCwxLTFWNGExLDEsMCwwLDAtMS0xWm00LDE2SDE1VjVoM1oiLz48L3N2Zz4=';

const next: string = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyBmaWxsPSIjMjYyNjI2IiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4wMjgsMjAuODgyYTEsMSwwLDAsMCwxLjAyNy0uMDVsMTItOGExLDEsMCwwLDAsMC0xLjY2NGwtMTItOEExLDEsMCwwLDAsMy41LDRWMjBBMSwxLDAsMCwwLDQuMDI4LDIwLjg4MlpNNS41LDUuODY5LDE0LjcsMTIsNS41LDE4LjEzMVpNMTguNSwxOFY2YTEsMSwwLDAsMSwyLDBWMThhMSwxLDAsMCwxLTIsMFoiLz48L3N2Zz4=';

const previous: string = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyBmaWxsPSIjMjYyNjI2IiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi45NDUsMTIuODMybDEyLDhBMSwxLDAsMCwwLDIwLjUsMjBWNGExLDEsMCwwLDAtMS41NTUtLjgzMmwtMTIsOGExLDEsMCwwLDAsMCwxLjY2NFpNMTguNSw1Ljg2OVYxOC4xMzFMOS4zLDEyWk0zLjUsMThWNmExLDEsMCwwLDEsMiwwVjE4YTEsMSwwLDAsMS0yLDBaIi8+PC9zdmc+';

export {
  play,
  pause,
  next,
  previous
};
