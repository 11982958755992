import * as api from '.';

const audioApi = {

  getData: async () => {
    return api.GET('/api/audio/data', null, null);
  }

};

export default audioApi;
