import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import {
  Image,
  Slider,
  Space,
  SpinLoading,
  Toast
} from 'antd-mobile';
import { recordPlayerBack, recordPlayerFront } from '../data/recordPlayerImageData';
import refreshImageData from '../data/refreshImageData';
import { play, pause, next, previous } from '../data/controlBarImageData';
import audioApi from '../api/audioApi';

const Audio: React.FC = () => {
  const location: Location = useLocation();
  const pathname: string = location.pathname;
  const parts: string[] = pathname.split('/');
  const text: string = parts.length >= 3 ? parts[2] : '';
  const indexes: string[] = text.split('-');
  let index: number = (Number(indexes[0]) || 1);
  let audioIndex: number = ((indexes.length > 1 ? Number(indexes[1]) : 1) || 1);
  let lastRequestTime: number = 0;

  const [loading, setLoading] = useState<boolean>(false);
  const [audios, setAudios] = useState<any[]>([{
    name: '.',
    url: '/api/word/audio/error'
  }]);
  const [paused, setPaused] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [currentTimeText, setCurrentTimeText] = useState<string>('00:00');
  const [durationText, setDurationText] = useState<string>('00:00');

  const loadData = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await audioApi.getData();
    const data: any[] = res.data || [];
    let currentAudios: any[] = [];
    index = Math.max(Math.min(index, data.length), 1) - 1;
    if (index <= data.length - 1) {
      currentAudios = data[index].children || [];
    }
    if (!currentAudios.length) {
      currentAudios = [{
        name: '.',
        url: '/api/word/audio/error'
      }];
    }
    setAudios(currentAudios);
    setLoading(false);
    audioIndex = Math.max(Math.min(audioIndex, currentAudios.length), 1) - 1;
    setCurrentIndex(audioIndex);
    const audio: any = window.document.getElementById('audio');
    audio.src = currentAudios[audioIndex].url;
  };

  const playOrPauseAudio = () => {
    if (paused) {
      playAudio();
    } else {
      pauseAudio();
    }
  };

  const nextAudio = () => {
    stopAudio();
    if (currentIndex >= audios.length - 1) {
      Toast.show({
        content: '已是最后一个音频'
      });
      return;
    }
    const currentAudioIndex = currentIndex + 1
    setCurrentIndex(currentAudioIndex);
    const audio: any = window.document.getElementById('audio');
    audio.src = audios[currentAudioIndex].url;
  };

  const previousAudio = () => {
    stopAudio();
    if (currentIndex <= 0) {
      Toast.show({
        content: '已是第一个音频'
      });
      return;
    }
    const currentAudioIndex = Math.min(currentIndex - 1, audios.length - 1);
    setCurrentIndex(currentAudioIndex);
    const audio: any = window.document.getElementById('audio');
    audio.src = audios[currentAudioIndex].url;
  };

  const playAudio = () => {
    const audio: any = window.document.getElementById('audio');
    audio.play();
    setPaused(false);
  };

  const pauseAudio = () => {
    const audio: any = window.document.getElementById('audio');
    audio.pause();
    setPaused(true);
  };

  const stopAudio = () => {
    const audio: any = window.document.getElementById('audio');
    audio.pause();
    audio.currentTime = 0;
    setPaused(true);
    updateAudioProgress();
  };

  const seekAudio = (offset: number) => {
    if (paused) {
      Toast.show({
        content: '音频未开始播放'
      });
      return;
    }
    const audio: any = window.document.getElementById('audio');
    const currentTime: number = audio.currentTime;
    const duration: number = audio.duration;
    if (!isNaN(currentTime) && !isNaN(duration)) {
      let time: number = currentTime + offset;
      time = Math.min(Math.max(time, 0), Math.max(duration - 1, 0));
      audio.currentTime = time;
    }
  };

  const setAudioProgress = (value: number) => {
    if (paused) {
      return;
    }
    const audio: any = window.document.getElementById('audio');
    const duration: number = audio.duration;
    if (!isNaN(duration)) {
      audio.currentTime = duration * value / 100;
    }
  };

  const updateAudioProgress = () => {
    const audio: any = window.document.getElementById('audio');
    const currentTime: number = audio.currentTime;
    const duration: number = audio.duration;
    if (!isNaN(currentTime) && !isNaN(duration)) {
      var progress: number = currentTime / duration * 100;
      setProgress(progress);
      setCurrentTimeText(formatTime(currentTime));
      setDurationText(formatTime(duration));
    } else {
      setProgress(0);
      setCurrentTimeText('00:00');
      setDurationText('00:00');
    }
  };

  const formatTime = (seconds: number) => {
    seconds = isNaN(seconds) ? 0 : Math.round(seconds);
    seconds = seconds > Number.MAX_SAFE_INTEGER ? 0 : seconds;
    let minutes = Math.floor(seconds / 60);
    let minuteText = minutes.toFixed(0);
    let secondText = (seconds - minutes * 60).toFixed(0);
    return (minuteText.length < 2 ? '0' : '') + minuteText + ':' + (secondText.length < 2 ? '0' : '') + secondText;
  };

  useEffect(() => {
    const audio: any = window.document.getElementById('audio');
    audio.src = '/api/word/audio/error';
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <audio
        id="audio"
        onEnded={() => stopAudio()}
        onTimeUpdate={() => updateAudioProgress()}
      />
      <div className="audio-page">
        {
          loading ? (
            <Space
              align="center"
              direction="vertical"
              className="loading-box"
            >
              <SpinLoading color="primary" />
              <span>加载中...</span>
            </Space>
          ) : (
            <div className="record-player-container">
              <Space
                align="center"
                direction="vertical"
              >
                <div className="record-player">
                  <Image
                    src={recordPlayerBack}
                    fit="fill"
                    placeholder={null}
                    className="back"
                  />
                  <div className={`logo ${!paused ? 'spinning' : ''}`}>
                    <Image
                      src="/images/listening.jpg"
                      fit="fill"
                      placeholder={null}
                    />
                  </div>
                  <Image
                    src={recordPlayerFront}
                    fit="fill"
                    placeholder={null}
                    className="front"
                  />
                </div>
                <h3 className="audio-title">{audios[currentIndex]?.name || '.'}</h3>
              </Space>
              <h4 className="ad-text">
                《9988雅思听力》配套听力录播课下载、听课计划
                <br />
                请加微信公众号：9988英语
              </h4>
              <div className="control-bar">
                <Slider
                  max={100}
                  min={0}
                  step={1}
                  value={progress}
                  disabled={paused}
                  onChange={value => setAudioProgress(Number(value))}
                />
                <div className="h-container time-text">
                  <span>{currentTimeText}</span>
                  <span>{durationText}</span>
                </div>
                <div className="h-container speed-buttons">
                  <div className="h-container speed-button-box">
                    <div
                      className="button speed-button left-button"
                      onClick={() => seekAudio(-30)}
                    >
                      <Image
                        src={refreshImageData}
                        fit="fill"
                        placeholder={null}
                      />
                      <span>-30</span>
                    </div>
                    <div
                      className="button speed-button left-button"
                      onClick={() => seekAudio(-5)}
                    >
                      <Image
                        src={refreshImageData}
                        fit="fill"
                        placeholder={null}
                      />
                      <span>-5</span>
                    </div>
                  </div>
                  <div className="h-container speed-button-box">
                    <div
                      className="button speed-button right-button"
                      onClick={() => seekAudio(5)}
                    >
                      <Image
                        src={refreshImageData}
                        fit="fill"
                        placeholder={null}
                      />
                      <span>+5</span>
                    </div>
                    <div
                      className="button speed-button right-button"
                      onClick={() => seekAudio(30)}
                    >
                      <Image
                        src={refreshImageData}
                        fit="fill"
                        placeholder={null}
                      />
                      <span>+30</span>
                    </div>
                  </div>
                </div>
                <div className="h-container buttons">
                  <div
                    className="button previous-button"
                    onClick={() => previousAudio()}
                  >
                    <Image
                      src={previous}
                      fit="fill"
                      placeholder={null}
                    />
                  </div>
                  <div
                    className="button play-button"
                    onClick={() => playOrPauseAudio()}
                  >
                    <Image
                      src={paused ? play : pause}
                      fit="fill"
                      placeholder={null}
                    />
                  </div>
                  <div
                    className="button next-button"
                    onClick={() => nextAudio()}
                  >
                    <Image
                      src={next}
                      fit="fill"
                      placeholder={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

export default Audio;
