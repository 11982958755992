import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { NavigateFunction } from 'react-router-dom';
import {
  Collapse,
  List,
  Space,
  SpinLoading
} from 'antd-mobile';
import audioApi from '../api/audioApi';

const AudioIndex: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [audios, setAudios] = useState<any[]>([]);
  const navigate: NavigateFunction = useNavigate();
  let lastRequestTime: number = 0;

  const openAudioPage = (index: number, audio: any) => {
    const audioIndex = audios[index].children.indexOf(audio);
    navigate(`/audio/${index + 1}-${audioIndex + 1}`);
  };

  const loadData = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await audioApi.getData();
    setAudios(res.data || []);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Space
      direction="vertical"
      className="audio-index-page"
    >
      <List header="《9988雅思听力》答案词 & 替换词配套音频" />
      <div className="page-content">
        {
          loading ? (
            <Space
              align="center"
              direction="vertical"
              className="loading-box"
            >
              <SpinLoading color="primary" />
              <span>加载中...</span>
            </Space>
          ) : (
            <Collapse accordion>
              {
                audios.map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={item.name}
                  >
                    <List className="audio-list">
                      {
                        (item.children || []).map((audio: any, audioIndex: number) => (
                          <List.Item
                            key={audioIndex.toString()}
                            onClick={() => openAudioPage(index, audio)}
                          >
                            {audio.name}
                          </List.Item>
                        ))
                      }
                    </List>
                  </Collapse.Panel>
                ))
              }
            </Collapse>
          )
        }
      </div>
    </Space>
  );
};

export default AudioIndex;
